import { create } from 'zustand';
import { GetProductDetailV1Rs } from '../../../../api/function/product/rs';
import { ProductPayType } from '../../../../repositories/product/detail/ProductPayType';
import { ProductCompositionDto } from '../../../../api/dto/ProductCompositionDto';
import { immer } from 'zustand/middleware/immer';
import { CompositionDto } from '../../../../api/dto/CompositionDto';
import { ProductType } from '../../../../repositories/usage/vo/ProductType';
import ProductCompositionType from '../../../../repositories/product/detail/ProductCompositionType';
import { UseType } from '../../../../api/types/UseType';

interface Actions {
  setData: (data: GetProductDetailV1Rs) => void;
  initComposition: (compositionList: CompositionDto[]) => void;
  setName: (name: string) => void;
  setDescription: (name: string) => void;
  setCompositionAttr: (
    type: ProductCompositionType,
    composition: Partial<ProductCompositionDto & { error?: boolean }>
  ) => void;
  setMatchingPrice: (val: number | null) => void;
  setPromotionPrice: (val: number | null) => void;
  setEtcPrice: (val: number | null) => void;
  setError: (key: string, message: string) => void;
  unlock: () => void;
  reset: () => void;
}

interface Data {
  productId: number | null;
  type: ProductType;
  name: string;
  description: string;
  payType: ProductPayType;
  compositionList: (ProductCompositionDto & { error?: boolean })[];
  matchingPrice: number | null;
  promotionPrice: number | null;
  etcPrice: number | null;
  price: number | null;
  locked: boolean;
}

interface Errors {
  [key: string]: string;
}

const defaultData: Data = {
  productId: null,
  type: 'CUSTOM_V1',
  name: '',
  compositionList: [],
  description: '',
  payType: 'ACHIEVE',
  matchingPrice: null,
  price: null,
  locked: false,
  etcPrice: null,
  promotionPrice: null,
};

interface IProductDetailStore {
  data: Data;
  errors: Errors;
  actions: Actions;
}

const defaultErrors: Errors = {};

export const useProductDetailStore = create<IProductDetailStore>()(
  immer((set) => ({
    data: defaultData,
    errors: defaultErrors,
    actions: {
      setName: (name: string) =>
        set(({ data, errors }) => {
          data.name = name;
          errors.name = '';
        }),
      setDescription: (desc: string) =>
        set(({ data }) => {
          data.description = desc;
        }),
      setData: (data: GetProductDetailV1Rs) =>
        set((state) => {
          state.data = { ...state.data, ...data };
          state.data.locked = true;
        }),
      initComposition: (list) =>
        set((state) => {
          state.data.compositionList = list.map((v) => ({
            compositionId: v.compositionId,
            name: v.name,
            divisionType: v.divisionType,
            requiredType: v.requiredType,
            type: v.type,
            useType: v.requiredType === 'REQUIRED' ? UseType.USE : UseType.UNUSED,
            overuseType: UseType.UNUSED,
            price: 0,
            limitType: 'LIMIT',
            quantity: v.requiredType === 'REQUIRED' ? 1 : 0,
          }));
        }),
      setCompositionAttr: (type: ProductCompositionType, composition: Partial<ProductCompositionDto>) =>
        set((state) => {
          const idx = state.data.compositionList.findIndex((v) => v.type === type);
          if (idx > -1) {
            state.data.compositionList[idx].error = false;
            state.data.compositionList[idx] = { ...state.data.compositionList[idx], ...composition };
          }
        }),
      setMatchingPrice: (val) =>
        set(({ data, errors }) => {
          data.matchingPrice = val;
          errors.matchingPrice = '';
        }),
      setPromotionPrice: (val) =>
        set(({ data, errors }) => {
          data.promotionPrice = val;
          errors.promotionPrice = '';
        }),
      setEtcPrice: (val) =>
        set(({ data, errors }) => {
          data.etcPrice = val;
          errors.etcPrice = '';
        }),
      setError: (key, message) =>
        set((state) => {
          state.errors[key] = message;
        }),
      reset: () =>
        set((state) => {
          state.data = defaultData;
        }),
      unlock: () =>
        set((state) => {
          state.data.locked = false;
        }),
    },
  }))
);

export const totalPriceSelector = ({
  data: { matchingPrice, etcPrice, promotionPrice, compositionList },
}: IProductDetailStore) => {
  const compositionTotalPrice = compositionList.reduce((acc, v) => {
    if (v.limitType === 'UNLIMITED') {
      return acc;
    }

    return acc + (v.quantity ?? 0) * v.price;
  }, 0);
  return compositionTotalPrice + Number(matchingPrice) + Number(etcPrice) + Number(promotionPrice);
};
