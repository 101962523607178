// Created by kdw0601 on 2022-08-31
import styles from './ContractDetailForm.module.scss';
import classNames from 'classnames/bind';
import ContractField from '../../../components/contract/detail/ContractField';
import { DatePicker } from '@midasit/date';
import Input from '../../../components/challenge/create/common/Input';
import ContractLineButton from '../../../components/contract/common/ContractLineButton';
import NewSelect from '../../../components/common/select/NewSelect';
import BlackButton from '../../../components/challenge/create/common/BlackButton';
import React from 'react';
import useContractDetailForm from './useContractDetailForm';
import Modal from '../../../components/common/modal/Modal';
import ButtonCommon from '../../../components/common/button/ButtonCommon';
import { ContractStatus, ContractStatusType } from '../../../repositories/contract/vo/ContractStatus';
import ContractPeriod from './ContractPeriod';
import { ContractAttachFileType } from '../../../api/types/ContractAttachFileType';
import ContractAttachFileView from './ContractAttachFileView';
import ProductDetailV1Composition from '../../../components/product/detail/ProductDetailV1Composition';
import { useCheckAuthorizedAction } from '../../../feature/authority/utils/withAuthority';
import LineButton from '../../../components/challenge/create/common/LineButton';
import ProductDetailV1Pricing from '../../../components/product/detail/ProductDetailV1Pricing';

const cx = classNames.bind(styles);

interface Props {
  id?: number;
  mainContractId?: number;
}

const ContractDetailForm = ({ id, mainContractId: mainContractIdParam }: Props) => {
  const {
    businessNumberRegis,
    companyNameRegis,
    nameRegis,
    phoneRegis,
    emailRegis,
    memoRegis,
    onSubmit,
    contractDate,
    setContractDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getBusinessNumberInfo,
    onChangeSimpleProductInfo,
    handleFileUpload,
    simpleProductList,
    contractStatus,
    setContractStatus,
    attachFileList,
    selectedProduct,
    errors,
    productIdRegis,
    isContractComplete,
    isContractCanceled,
    isContractCompleteOrCanceled,
    handleDeleteAttachFile,
    isShowVerifyModal,
    handleClickModalConfirm,
    handleClickModalCancel,
    handleClickFileLink,
    startDateRegis,
    endDateRegis,
    contractDateRangeError,
    contractDateRegis,
    handleClickBack,
    isWaitingForApproval,
    isRejected,
    mainContractNumber,
    mainContractDateRange,
    mainContractId,
  } = useContractDetailForm({ id, mainContractId: mainContractIdParam });
  const isAuthorizedApproveContract = useCheckAuthorizedAction('approveContract');
  const isNew = !id;
  const notEditable = isAuthorizedApproveContract && !isNew && (isWaitingForApproval || !isRejected);
  const isAdditionalContract = !!mainContractId;

  const contractStatusOptions = isWaitingForApproval
    ? [ContractStatusType.COMPLETE, ContractStatusType.REJECT]
    : isContractComplete
    ? [ContractStatusType.COMPLETE, ContractStatusType.TERMINATION, ContractStatusType.CANCEL]
    : [];

  return (
    <form className={cx('container')} onSubmit={onSubmit}>
      <div className={cx('box')}>
        {/*계약정보*/}
        <div className={cx('sub-box')}>
          <strong className={cx('sub-title')}>계약정보</strong>
          <ContractField
            leftName={'계약일자'}
            leftRequired
            errorField={errors.contractDate}
            left={
              <DatePicker
                date={contractDate}
                disabled={notEditable}
                onChange={(date) => setContractDate(date)}
                customInput={<Input {...contractDateRegis} className={cx('picker')} />}
                minDate={new Date()}
              />
            }
          />
          <ContractField
            leftName={'사업자등록번호'}
            leftRequired
            errorField={errors.businessNumber}
            left={
              <div className={cx('business-input')}>
                <Input
                  disabled={notEditable || isAdditionalContract || !isNew}
                  {...businessNumberRegis}
                  type={'text'}
                  maxLength={10}
                  name={'businessNumber'}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      await getBusinessNumberInfo();
                    }
                  }}
                  placeholder={'하이픈(-) 제외 10자리 입력'}
                />
                <ContractLineButton
                  disabled={notEditable || isAdditionalContract || !isNew}
                  className={cx('margin-left')}
                  onClick={getBusinessNumberInfo}
                >
                  조회
                </ContractLineButton>
              </div>
            }
          />
          <ContractField
            leftName={'기업명'}
            left={<Input {...companyNameRegis} name={'companyName'} readOnly />}
            rightName={'기업담당자명'}
            right={<Input {...nameRegis} name={'name'} readOnly />}
          />
          <ContractField
            leftName={'담당자 이메일'}
            left={<Input {...emailRegis} name={'email'} readOnly />}
            rightName={'담당자 휴대폰 번호'}
            right={<Input {...phoneRegis} name={'phone'} readOnly />}
          />
          <ContractField
            leftName={'계약서 첨부'}
            rightName={'견적 첨부'}
            left={
              <ContractAttachFileView
                fileType={ContractAttachFileType.CONTRACT}
                fileList={attachFileList}
                onChangeFile={handleFileUpload}
                onClickFile={handleClickFileLink}
                disabled={notEditable || attachFileList.length >= 5}
                deleteDisabled={notEditable}
                onDelete={handleDeleteAttachFile}
              />
            }
            right={
              <ContractAttachFileView
                fileType={ContractAttachFileType.ESTIMATE}
                fileList={attachFileList}
                onChangeFile={handleFileUpload}
                onClickFile={handleClickFileLink}
                disabled={notEditable || attachFileList.length >= 5}
                deleteDisabled={notEditable}
                onDelete={handleDeleteAttachFile}
              />
            }
          />
          <ContractField
            leftName={'메모'}
            left={
              <textarea
                {...memoRegis}
                name={'memo'}
                className={cx('textarea')}
                placeholder={'1,000자 이내'}
                maxLength={1000}
              />
            }
            block
          />
          {mainContractId && (
            <ContractField
              leftName={'상위계약'}
              left={
                <ContractLineButton
                  className={cx('margin-left')}
                  style={{ color: 'blue' }}
                  onClick={() => {
                    window.open(`/contract/${mainContractId}`, '_blank');
                  }}
                >
                  {mainContractNumber}
                </ContractLineButton>
              }
              block
            />
          )}
        </div>

        {/* 계약상품 정보 */}
        <div className={cx('sub-box')}>
          <strong className={cx('sub-title')}>계약상품 정보</strong>
          <ContractPeriod
            startDate={startDate}
            endDate={endDate}
            startDateRegis={startDateRegis}
            endDateRegis={endDateRegis}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            error={contractDateRangeError}
            isContractCanceled={isContractCanceled}
            contractId={id}
            isContractComplete={isContractComplete}
            disabled={notEditable}
            range={mainContractDateRange}
          />
          <ContractField
            leftName={'판매상품명'}
            errorField={errors.productId}
            leftRequired
            left={
              <NewSelect
                name={'product.name'}
                value={selectedProduct}
                className={cx('select')}
                onChange={(option) => onChangeSimpleProductInfo(option)}
                disabled={notEditable || isAdditionalContract}
                options={
                  simpleProductList?.map(({ value, label }) => ({
                    value,
                    label,
                  })) ?? []
                }
                size={'large'}
              />
            }
          />
          <ContractField
            leftName={'상품구성'}
            left={
              <div style={{ width: '100%' }}>
                <div className={cx('composition-table')}>
                  <ProductDetailV1Composition
                    showUsage={isContractCompleteOrCanceled}
                    showQuantityPrice
                    editableQuantityPrice={!notEditable}
                  />
                </div>
                <ProductDetailV1Pricing />
              </div>
            }
          />
        </div>
        {/* 계약상태 */}
        {!isNew && isAuthorizedApproveContract && (isWaitingForApproval || isContractCompleteOrCanceled) && (
          <div className={cx('sub-box')}>
            <strong className={cx('sub-title')}>계약상태</strong>
            <ContractField
              leftName={'계약상태'}
              leftRequired
              left={
                <NewSelect
                  className={cx('select', 'second')}
                  disabled={isContractCanceled || !isAuthorizedApproveContract}
                  onChange={(val) => setContractStatus(val?.value as ContractStatusType)}
                  name={'status'}
                  value={{
                    label: ContractStatus[contractStatus],
                    value: contractStatus,
                  }}
                  options={contractStatusOptions.map((value) => ({
                    label: ContractStatus[value],
                    value,
                  }))}
                  size={'large'}
                />
              }
            />
          </div>
        )}
        <div className={cx('buttons')}>
          {/* 뒤로가기 */}
          <LineButton type={'button'} onClick={handleClickBack}>
            뒤로
          </LineButton>
          {/* 계약 승인 요청*/}
          {isAuthorizedApproveContract && !isContractCompleteOrCanceled && (
            <BlackButton
              type={'submit'}
              size={'large'}
              disabled={!isNew && isWaitingForApproval && contractStatus === 'WAIT'}
            >
              <>
                {isNew && '계약 승인 요청'}
                {!isNew && isRejected && '계약 승인 재요청'}
                {!isNew && isWaitingForApproval && '저장하기'}
              </>
            </BlackButton>
          )}
          {isAuthorizedApproveContract && isContractComplete && (
            <BlackButton type={'submit'} size={'large'}>
              저장하기
            </BlackButton>
          )}
        </div>
      </div>
      <input type={'text'} hidden value={selectedProduct?.value ?? ''} {...productIdRegis} />
      {isShowVerifyModal && (
        <Modal contentClassName={cx('verify-modal-container')}>
          <div className={cx('title')}>
            {contractStatus === 'COMPLETE'
              ? '계약 완료 후에는, 계약 내용을 변경할 수 없습니다.'
              : `계약 ${contractStatus === 'TERMINATION' ? '종료' : '취소'} 후에는, 모든 내용 수정이 불가능합니다.`}
          </div>
          <div className={cx('sub')}>작성하신 내용으로 최종 저장 하시겠습니까?</div>
          <div className={cx('buttons')}>
            <ButtonCommon type={'secondary'} onClick={handleClickModalCancel}>
              취소
            </ButtonCommon>
            <ButtonCommon submitType onClick={handleClickModalConfirm}>
              저장
            </ButtonCommon>
          </div>
        </Modal>
      )}
    </form>
  );
};

export default ContractDetailForm;
