// Created by kdw0601 on 2022-05-30
import React, { useId, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ProductItemRow.module.scss';
import { useProductDetailStore } from './store/useProductDetailStore';
import Radio from '../../common/radio/Radio';
import ProductDetailV1ItemRow from './ProductDetailV1ItemRow';

const cx = classNames.bind(styles);

interface Props {
  showUsage?: boolean;
  showQuantityPrice?: boolean;
  editableQuantityPrice?: boolean;
}

const ProductDetailV1NCCRequestRow = (props: Props) => {
  const { showUsage, showQuantityPrice, editableQuantityPrice } = props;
  const integrateTrueId = useId();
  const integrateFalseId = useId();

  // 통합 관리
  const integratedComp = useProductDetailStore((v) =>
    v.data.compositionList.find((v) => v.type === 'NCC_REQUEST_QUANTITY')
  );
  const accaComp = useProductDetailStore((v) => v.data.compositionList.find((v) => v.type === 'ACCA_REQUEST_QUANTITY'));
  const phsComp = useProductDetailStore((v) => v.data.compositionList.find((v) => v.type === 'PHS_REQUEST_QUANTITY'));
  const [integrateYn, setIntegrateYn] = useState(accaComp?.useType === 'UNUSED' && phsComp?.useType === 'UNUSED');
  const locked = useProductDetailStore((state) => state.data.locked);

  const set = useProductDetailStore((v) => v.actions.setCompositionAttr);

  if (!(integratedComp && accaComp && phsComp)) {
    return null;
  }

  const handleChangeIntegrateYn = (integrateYn: boolean) => {
    setIntegrateYn(integrateYn);
    if (!integrateYn) {
      set('NCC_REQUEST_QUANTITY', { useType: 'UNUSED', limitType: 'LIMIT', quantity: 0 });
    } else {
      set('ACCA_REQUEST_QUANTITY', { useType: 'UNUSED', limitType: 'LIMIT', quantity: 0 });
      set('PHS_REQUEST_QUANTITY', { useType: 'UNUSED', limitType: 'LIMIT', quantity: 0 });
    }
  };

  return (
    <>
      <tr style={{ borderTop: '1px solid #eee' }}>
        <td>
          <div className={cx('ncc-radio-wrap')}>
            검사 요청{' '}
            <label htmlFor={integrateTrueId}>
              <Radio
                disabled={locked}
                id={integrateTrueId}
                checked={integrateYn}
                onClick={() => handleChangeIntegrateYn(true)}
              />{' '}
              통합관리
            </label>
            <label htmlFor={integrateFalseId}>
              <Radio
                disabled={locked}
                id={integrateFalseId}
                checked={!integrateYn}
                onClick={() => handleChangeIntegrateYn(false)}
              />{' '}
              분리관리
            </label>
          </div>
        </td>
      </tr>
      <ProductDetailV1ItemRow
        altName={'역검 LITE 요청'}
        type={'ACCA_LITE_REQUEST_QUANTITY'}
        className={cx('ncc-row')}
        disabled
        showUsage={showUsage}
        showQuantityPrice={showQuantityPrice}
        editableQuantityPrice={editableQuantityPrice}
      />
      <ProductDetailV1ItemRow
        altName={'통합 관리'}
        type={'NCC_REQUEST_QUANTITY'}
        className={cx('ncc-row', !integrateYn && 'disabled')}
        disabled={!integrateYn || locked}
        showUsage={showUsage}
        showQuantityPrice={showQuantityPrice}
        editableQuantityPrice={editableQuantityPrice}
      />
      <ProductDetailV1ItemRow
        altName={'역검 PRO 요청'}
        type={'ACCA_REQUEST_QUANTITY'}
        className={cx('ncc-row', integrateYn && 'disabled')}
        disabled={integrateYn || locked}
        showUsage={showUsage}
        showQuantityPrice={showQuantityPrice}
        editableQuantityPrice={editableQuantityPrice}
      />
      <ProductDetailV1ItemRow
        altName={'개발자검사 요청'}
        type={'PHS_REQUEST_QUANTITY'}
        className={cx('ncc-row', 'end', integrateYn && 'disabled')}
        disabled={integrateYn || locked}
        showUsage={showUsage}
        showQuantityPrice={showQuantityPrice}
        editableQuantityPrice={editableQuantityPrice}
      />
    </>
  );
};

export default ProductDetailV1NCCRequestRow;
