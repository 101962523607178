// Created by kdw0601 on 2024-08-08
import { usePopupDetailStore } from './hooks/usePopupDetailStore';
import React from 'react';
import InputText from '../../common/input/InputText';

const PopupDetailImageUpload = () => {
  const { set } = usePopupDetailStore((state) => state.actions);
  const connectionLink = usePopupDetailStore((state) => state.data.connectionLink);

  return (
    <InputText
      defaultValue={connectionLink}
      maxLength={50}
      onChange={(e) => set('connectionLink')(e.currentTarget.value)}
      style={{ width: 800 }}
    />
  );
};

export default PopupDetailImageUpload;
