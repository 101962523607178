// Created by kdw0601 on 2022-05-30
import Toggle from '../../common/toggle/Toggle';
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ProductItemRow.module.scss';
import InputNumber from '../../common/input/InputNumber';
import { useProductDetailStore } from './store/useProductDetailStore';
import ProductCompositionType from '../../../repositories/product/detail/ProductCompositionType';
import { UseType } from '../../../api/types/UseType';
import { toggleValue } from '../../../util/toggleValue';

const cx = classNames.bind(styles);

interface Props {
  type: ProductCompositionType;
  altName?: string;
  className?: string;
  disabled?: boolean;
  // 계약 상세 페이지에서 사용량 출력 용도
  showUsage?: boolean;
  showQuantityPrice?: boolean;
  editableQuantityPrice?: boolean;
}

const ProductItemRow = ({
  type,
  altName,
  className,
  disabled,
  showUsage,
  showQuantityPrice,
  editableQuantityPrice,
}: Props) => {
  const set = useProductDetailStore((v) => v.actions.setCompositionAttr);
  const data = useProductDetailStore((v) => v.data.compositionList.find((v) => v.type === type));

  if (!data) {
    return null;
  }

  const {
    compositionId,
    name,
    divisionType,
    useType,
    requiredType,
    limitType,
    quantity = 0,
    error,
    overuseType,
    remainingQuantity = 0,
    price,
  } = data;

  // 설정 고정 관련 effect
  useEffect(() => {
    // 지원자 수 무제한 OFF일 경우 초과허용 무조건 ON
    if (type === 'APPLICANT_QUANTITY' && limitType === 'LIMIT') {
      set(type, { overuseType: UseType.USE });
    }

    // 역검 LITE 요청의 경우 사용여부 ON, 무제한 ON으로 고정
    if (type === 'ACCA_LITE_REQUEST_QUANTITY') {
      set(type, { useType: UseType.USE, limitType: 'UNLIMITED' });
    }
  }, [limitType]);

  const isOveruseDisabled = type === 'APPLICANT_QUANTITY';

  const handleChangeUse = () => {
    const newUseType = toggleValue(useType, 'USE', 'UNUSED');

    // 사용여부 OFF인 경우 무제한 여부, 초과 허용 여부도 OFF로 바꾼다.
    set(type, {
      useType: newUseType,
      limitType: newUseType === 'UNUSED' ? 'LIMIT' : limitType,
      quantity: newUseType === 'USE' ? 1 : 0,
      overuseType: newUseType === 'UNUSED' ? 'UNUSED' : overuseType,
    });
  };

  const handleChangeLimitType = () => {
    const newLimitType = toggleValue(limitType, 'LIMIT', 'UNLIMITED');

    set(type, { limitType: newLimitType, overuseType: newLimitType === 'UNLIMITED' ? UseType.UNUSED : overuseType });
  };

  const handleChangeOveruseType = () => {
    set(type, { overuseType: toggleValue(overuseType, UseType.USE, UseType.UNUSED) });
  };

  const handleChangeQuantity = (quantity: number) => {
    set(type, { quantity, error: false });
  };

  const handleChangePrice = (price: number) => {
    set(type, { price, error: false });
  };

  return (
    <tr key={compositionId} className={className}>
      {/*항목명*/}
      <td>{altName || name}</td>
      {/*사용 여부*/}
      <td>
        <div className={cx('toggle-wrap', requiredType === 'REQUIRED' && 'red')}>
          {requiredType === 'REQUIRED' ? (
            ' *필수'
          ) : (
            <Toggle disabled={disabled} checked={useType === 'USE'} onClick={handleChangeUse} />
          )}
        </div>
      </td>
      {/* 무제한 여부*/}
      <td>
        <div className={cx('toggle-wrap')}>
          {divisionType === 'QUANTITY' ? (
            <Toggle
              checked={limitType === 'UNLIMITED'}
              disabled={disabled || useType === 'UNUSED'}
              onClick={handleChangeLimitType}
            />
          ) : (
            '-'
          )}
        </div>
      </td>
      {/* 초과 허용 여부 */}
      <td>
        <div className={cx('toggle-wrap')}>
          {divisionType === 'QUANTITY' ? (
            <Toggle
              checked={overuseType === UseType.USE}
              disabled={isOveruseDisabled || disabled || useType === 'UNUSED' || limitType === 'UNLIMITED'}
              onClick={handleChangeOveruseType}
            />
          ) : (
            '-'
          )}
        </div>
      </td>

      {showQuantityPrice && (
        <>
          {/* 수량 */}
          <td>
            {divisionType === 'QUANTITY' ? (
              <InputNumber
                placeholder={'수량을 입력하세요'}
                min={1}
                value={limitType === 'UNLIMITED' ? '무제한' : quantity}
                className={cx('price')}
                disabled={(disabled && !editableQuantityPrice) || useType === 'UNUSED' || limitType === 'UNLIMITED'}
                onChange={(v) => handleChangeQuantity(isNaN(v) ? 0 : v)}
                error={error ? ' ' : undefined}
              />
            ) : (
              '-'
            )}
          </td>
          {/* 단가 */}
          <td>
            {divisionType === 'QUANTITY' ? (
              <InputNumber
                placeholder={'단가를 입력하세요'}
                min={1}
                value={price}
                className={cx('price')}
                disabled={(disabled && !editableQuantityPrice) || useType === 'UNUSED'}
                onChange={(v) => handleChangePrice(isNaN(v) ? 0 : v)}
                error={error ? ' ' : undefined}
              />
            ) : (
              '-'
            )}
          </td>
          {/* 계 */}
          <td>
            {divisionType === 'QUANTITY' ? (
              <InputNumber
                placeholder={'단가를 입력하세요'}
                min={1}
                value={(price * (limitType === 'UNLIMITED' ? 1 : quantity)).toLocaleString()}
                className={cx('price', 'align-right')}
                disabled
              />
            ) : (
              '-'
            )}
          </td>
        </>
      )}
      {showUsage &&
        (limitType === 'UNLIMITED' || divisionType === 'MODULE' ? (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        ) : (
          <>
            {/*계약 수량*/}
            <td>{quantity}</td>
            {/*사용 수량*/}
            <td>{quantity - remainingQuantity}</td>
            {/*잔여 수량*/}
            <td>{Math.max(remainingQuantity, 0)}</td>
            {/*초과 수량*/}
            <td>{Math.max(-remainingQuantity, 0)}</td>
          </>
        ))}
    </tr>
  );
};

export default ProductItemRow;
