import { RoleType } from '../../../api/types/RoleType';

type AuthorityMap = {
  [key: string]: {
    [key in RoleType]: boolean;
  };
};

export const authorityByAccountMap = {
  /** 기업계정 목록 열람 */
  tenantList: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: true,
  },
  /** 상품 목록 조회 */
  productList: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: true,
  },
  /** 상품 생성 */
  createContract: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: false,
    OPERATIONS: false,
  },
  /** 가입 승인 */
  approveTenant: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: false,
    OPERATIONS: true,
  },
  /** 계약 목록 조회 */
  contractList: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: true,
  },
  /** 계약 생성/수정/사용여부 */
  createEditUseProduct: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: false,
    OPERATIONS: false,
  },
  /** 계약 승인 */
  approveContract: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: false,
    OPERATIONS: false,
  },
  /** 사용량 조회 */
  usage: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: true,
  },
  /** 정산 내역 */
  settles: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: false,
  },
  /** 매칭 인재 현황 */
  matchingStatus: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: true,
    OPERATIONS: true,
  },
  /** 팝업 게시 */
  popup: {
    MASTER: true,
    BUSINESS_PLANNING: true,
    SALES: false,
    OPERATIONS: true,
  },
  /** 매칭 챌린지 */
  matchingChallenge: {
    MASTER: true,
    BUSINESS_PLANNING: false,
    SALES: false,
    OPERATIONS: true,
  },
} as const satisfies AuthorityMap;

export type AuthorityRequiredActionType = keyof typeof authorityByAccountMap;
